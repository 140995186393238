import { makeAutoObservable, runInAction } from 'mobx'
import cartApi from '../api/cart/cartApi'
import updateUserCart from '../api/cart/updateUserCart'
import userStore from './UserStore'

class CartStore {
    items = []

    constructor () {
        makeAutoObservable(this)
        this.getUserCart()
    }

    getUserCart = async (params) => {
        if (!userStore.isAuth) return this.items = []
        const tovars = await cartApi()
        
        runInAction(() => {
            this.items = tovars
        })
    }

    updateCartItems = async () => {
        const update = await updateUserCart(this.items)
        if (!update) return console.log('Не удалось выполнить запрос на обновление корзины')
    }

    addTovar = () => {
        this.getUserCart()
    }

    setItems = (its) => {
        this.items = its
    }
}

const cartStore = new CartStore()
export default cartStore