const MyAccountProfile = () => {
    return (
        <div id="account">
            <h1>My Account</h1>
            <div className="wrapper">
                <div className="navigation">
                    <button>Orders</button>
                    <button>Addresses</button>
                    <button>Payment method</button>
                    <button>Account details</button>
                    <button>Log out</button>
                </div>
                <div className="body">

                </div>
            </div>
        </div>
    )
}

export default MyAccountProfile;