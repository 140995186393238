import { Link } from 'react-router-dom';
import './Footer.scss'

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="left">
                    <p>Flussodibonta<br/>Contact us: <a href="mailto:info@flussodibonta.store">info@flussodibonta.store</a></p>
                    <div className="links">
                        <Link to="/tac">Terms & Privacy</Link>
                        <Link to="/my-account">My Account</Link>
                    </div>
                </div>
                <div className="right">
                    <p><strong>ИП</strong> Елин Никита Евгеньевич</p>
                    <p><strong>ИНН</strong> 420560673348</p>
                    <p><strong>ОГРН/ОГРНИП</strong> 324420500104178</p>
                    <p><strong>Телефон</strong> +7 913 280-10-86</p>
                </div>
            </div>
            <p className="copyright">&copy;2024 - flussodibonta.store</p>
        </footer>
    )
}

export default Footer;