import { makeAutoObservable } from 'mobx'
import checkTokenApi from '../api/auth/checkTokenApi'

class UserStore {
    isAuth = false

    constructor () {
        makeAutoObservable(this)
        this.checkUserAuth()
    }

    setAuth(auth) { this.isAuth = auth }

    checkUserAuth = async () => {
        const token = localStorage.getItem('token')
        if (!token) return this.isAuth = false

        const check = await checkTokenApi(token)
        if (!check.result) return this.isAuth = false

        localStorage.setItem('token', check.token)
        this.isAuth = true
    }
}

const userStore = new UserStore()
export default userStore