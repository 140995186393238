import CardOpacity from '../componens/CardOpacity/CardOpacity'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../componens/Footer/Footer'
import Header from '../componens/Header/Header'

import '../styles/Tops.scss'
import pageStore from '../store/PageStore'
import { observer } from 'mobx-react-lite'

const Category = observer(() => {
    const { name } = useParams()
	const [tovars, setTovars] = useState([])

	useEffect(() => {
		if (pageStore.pages.length === 0) return
		
		const pageId = pageStore.pages.filter(page => 
			page.pages.some(val => val.path === name)
		)
		const categoryId = pageId[0].pages.filter(pg => pg.path === name)[0].categoryId

		const topsTovars = async () => {
			const resp = await fetch(`${process.env.REACT_APP_SERVER_HOST}/api/v1/stuff?` + new URLSearchParams({category: categoryId}))

			if (resp.status === 200) {
				const result = await resp.json()
				setTovars(ungroupArray(result.result))
			}
		}

		topsTovars()
	}, [pageStore.pages, name])

	const ungroupArray = (array, size = 4) => {
		let newArray = []

		for (let i = 0; i < Math.ceil(array.length/size); i++) {
			newArray[i] = array.slice((i * size), (i * size) + size)
		}

		return newArray;
	}

	return (
		<>
			<Header/>
			{tovars.length > 0 ?
				<main className="tops-container">
					{tovars.map((tovar, index) => 
						<section className={'row'} key={index}>
							{tovar.map(item => 
								<CardOpacity key={item._id} card={item} />	
							)}
						</section>
					)}
				</main>	
			:
				<div className="empty-cart">
					<p>This category is empty.</p>
				</div>
			}
			<Footer/>
		</>
	)
})

export default Category