import './SCParagraf.scss'

const SCParagraf = ({content}) => {
    return (
        <div className="textoncard">
            <p>{content}</p>
        </div>
    )
}

export default SCParagraf