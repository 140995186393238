import Header from '../componens/Header/Header'
import React, { useEffect, useState } from 'react'

import SCImage from '../componens/SCImage/SCImage'
import SCParagraf from '../componens/SCParagraf/SCParagraf'
import SCTovar from '../componens/SCTovar/SCTovar'
import Footer from '../componens/Footer/Footer'

const Home = () => {
	const [showcase, setShowcase] = useState([])
 
	useEffect(() => {
		const fetchShowcase = async () => {
            try {
				const showcaseData = await fetch(`${process.env.REACT_APP_SERVER_HOST}/showcase`)
				const json = await showcaseData.json()
	
				if (showcaseData.status === 200) {
					setShowcase(json.result)
				}
			} catch (e) {
				console.error('Ошибка запроса на сервер')
			}
        }

        fetchShowcase()
	}, [])

	return (
		<>
			<Header/>
			{showcase.length > 0 ? 
				showcase.map(item => {
					if (item.type === 'image') {
						return (
							<section key={item._id}>
								<SCImage content={item.content[0]} />
							</section>
						)
					} else if (item.type === 'paragraf') {
						return (
							<section className="textpod" key={item._id}>
								<SCParagraf content={item.content[0]} />
							</section>
						)
					} else if (item.type === 'tovar') {
						return (
							<section className='content' key={item._id}>
								<SCTovar content={item.content} />
							</section>
						)
					}
				})
			: null}
			<Footer/>
		</>
	)
}

export default Home