export default async function addNumberToItemApi(data) {
    try {
        const resp = await fetch(`${process.env.REACT_APP_SERVER_HOST}/api/v1/cart`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(data)
        })
    
        const json = await resp.json()
    
        if (resp.status === 200) {
            return {result: true}
        } else {
            console.error(`Сервер ответил ${resp.status}. ${json.message}`)
        }
    } catch (e) {
        console.error('Не удалось отправить запрос серверу')
    }
}