import Category from '../pages/Category'
import Home from '../pages/Home'
import Tovar from '../pages/Tovar'
import { createBrowserRouter } from 'react-router-dom'
import MyAccount from '../pages/MyAccount';
import Cart from '../pages/Cart';
import Tac from '../pages/Tas';
import Hpi from '../pages/Hpi';

const router = createBrowserRouter([
	{
		path: "/tac",
		element: <Tac />
	},
	{
		path: '/hpi',
		element: <Hpi/>
	},
	{
		path: "/",
		element: <Home/>
	},
	{
		path: '/my-account',
		element: <MyAccount />
	},
	{
		path: "/tovar/:id",
		element: <Tovar />
	},
	{
		path: "/cart",
		element: <Cart />
	},
	{
		path: "/:name",
		element: <Category />
	}
]);

export default router