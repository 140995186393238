import './CardOpacity.scss'

const CardOpacity = ({card}) => {
    return (
        <a className="card" href={`/tovar/${card._id}`} key={card._id}>
            <div className="imgs">
                <div className="face">
                    <img src={`${process.env.REACT_APP_SERVER_HOST}/images/${card.images[0]}`} alt={`${card.name} face`} />
                </div>
                <div className="back">
                    <img src={`${process.env.REACT_APP_SERVER_HOST}/images/${card.images[1]}`} alt={`${card.name} back`} />
                </div>
            </div>
            <div className="subscription">
                <h5>{card.name}</h5>
                <p>{card.price}₽</p>
            </div>
        </a>
        
    )
}

export default CardOpacity;