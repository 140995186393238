import { makeAutoObservable, runInAction } from 'mobx'
import getPagesApi from '../api/pages/getPagesApi'

class PageStore {
    pages = []

    constructor () {
        makeAutoObservable(this)
        this.getPages()
    }

    getPages = async () => {
        const pagesResp = await getPagesApi()
        if (!pagesResp) return null
        
        runInAction(() => {
            this.pages = pagesResp
        })
    }

    setPages = (pg) => {
        this.pages = pg
    }
}

const pageStore = new PageStore()
export default pageStore