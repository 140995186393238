import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Header from "../componens/Header/Header"
import Footer from "../componens/Footer/Footer"
import userStore from "../store/UserStore"
import addToCartApi from "../api/cart/addToCartApi"
import cartStore from "../store/CartStore"

import '../styles/Tovar.scss'
import addToWitelistApi from "../api/tovar/addToWitelistApi"

import descrOne from '../images/card__tovar/description_one.png'
import descrTwo from '../images/card__tovar/description_two.png'
import { observer } from "mobx-react-lite"
import getOneTovarApi from "../api/tovar/getOneTovarApi"
import addNumberToItemApi from "../api/cart/addNumberToItemApi"

const Tovar = observer(() => {
    const { id } = useParams()
    const [ cartInput, setCardInput ] = useState(1)
    const [ maxStuffInput, setMaxStuffInput] = useState(1)
    const [ stuff, setStuff ] = useState({})
    const [ images, setImages ] = useState([]) 
    const [ currentImage, setCurrentImage ] = useState(0)
    const [ sizeSelect, setSizeSelect ] = useState('')
    const [ sizeError, setSizeError ] = useState(false)
    const [ imageStyle, setImageStyle ] = useState(`scale(1) translate(0, 0)`)
    const [ sizeOutOfStock, setSizeOutOfStock ] = useState(false)
    const [ witelistEmail, setWiteListEmail ] = useState('')
    const [ witelistSuccess, setWitelistSuccess ] = useState(false)
    const [ isDescription, setIsDescription ] = useState(true)

    useEffect(() => {
        const getStuffInfo = async () => {
            const tovars = await getOneTovarApi(id)
            setStuff(tovars)
            setImages(tovars.images)
        }
        getStuffInfo()
    }, [id])

    const handleAddToCart = async () => {
        if (!userStore.isAuth) return window.location.href = '/my-account'

        if (!sizeSelect) {
            setSizeError(true)
            setTimeout(() => setSizeError(false), 300)
        }

        const data = {
            stuffId: stuff._id,
            sizeId: sizeSelect,
            count: cartInput
        }

        const isInCart = cartStore.items.filter(it => it.stuffId._id === stuff._id && it.sizeId === sizeSelect)[0]
        if (isInCart) {
            const edit = await addNumberToItemApi(data)
            if (!edit) return
        } else {
            const add = await addToCartApi(data)
            if (!add) return
        }

        cartStore.addTovar()
    }

    const handleSizeSelect = (e, id) => {
        e.preventDefault()
        setSizeSelect(prev => id === prev ? '' : id)

        const currentSize = stuff.sizes.filter((val) => val._id === id)[0]
        setMaxStuffInput(currentSize.count)

        if (currentSize.count === 0) {
            setSizeOutOfStock(true)
        } else {
            setSizeOutOfStock(false)
        }
    }

    const handleCountInput = (e) => {
        let val = e.target.value

        val = val.replace(/[^0-9]/g, '')
        
        if (val < 0) val = 1
        if (val > maxStuffInput) val = maxStuffInput

        setCardInput(val)
    }

    const changeImageInGalley = (index) => {
        setCurrentImage(index)
    }

    const onImageMouseMove = (e) => {
        const height = e.nativeEvent.srcElement.clientHeight / 2
        const width = e.nativeEvent.srcElement.clientWidth / 2

        const offsetX = (width - e.clientX) / 2
        const offsetY = (height - e.clientY) / 2

        setImageStyle(`scale(2) translate(${offsetX}px, ${offsetY + 80}px)`)
    }

    const onImageMouseOut = () => {
        setImageStyle(`scale(1) translate(0, 0)`)
    }

    const addToWitelist = (e) => {
        e.preventDefault()

        const data_to_send = {
            stuffId: stuff._id,
            size: sizeSelect,
            email: witelistEmail
        }

        if (stuff.waitlist && stuff.waitlist.length > 0) {
            const isNoEmailInWiteList = stuff?.waitlist.filter(val =>
                val.email === witelistEmail
            )[0]
    
            if (isNoEmailInWiteList) return setWitelistSuccess(true);

            addToWitelistApi(data_to_send)
            setWitelistSuccess(true)
        } else {
            addToWitelistApi(data_to_send)
            setWitelistSuccess(true)
        }
    }


    return (
        stuff.images ?
        <>
            <Header/>
            <main className="tovar">
                <div className="tovar-container">
                    <div className="gallery">
                        <div 
                            className="current-image"
                            onMouseMove={onImageMouseMove}
                            onMouseOut={onImageMouseOut}
                        >
                            <img 
                                style={{transform: imageStyle}}
                                src={`${process.env.REACT_APP_SERVER_HOST}/images/${images[currentImage]}`} 
                                alt={`Tovar gallery ${currentImage}`} 
                            />
                        </div>
                        <div className="images-container">
                            {images.map((img, index) => 
                                <img 
                                    src={`${process.env.REACT_APP_SERVER_HOST}/images/${img}`} 
                                    key={index}
                                    className={index === currentImage ? 'active' : ''}
                                    onClick={() => changeImageInGalley(index)}
                                    alt={`Choice to preview`} 
                                />
                            )}
                        </div>
                    </div>
                    <div className="text-content">
                        <h2 className="tovar-name">{stuff.name}</h2>
                        <p className="tovar-price">{stuff.price}₽</p>
                        <button className="size-button">Size Guide</button>
                        <div className="tovar-description">
                            {stuff.description.map((item, index) =>
                                <li key={index}>{item}</li>
                            )}
                        </div>
                        <div className={`tovar-sizes ${sizeError ? ' error' : ''}`}>
                            <h5>
                                Size{sizeSelect !== '' ? ': ' + stuff.sizes.filter((val) => val._id === sizeSelect)[0]?.size : ''}
                            </h5>
                            <div className="buttons">
                                {stuff.sizes.map((item) => 
                                    <button 
                                        key={item._id} 
                                        className={sizeSelect === item._id ? 'checked' : ''} 
                                        onClick={e => handleSizeSelect(e, item._id)}
                                    >
                                        {item.size}
                                        <span>{item.size}</span>
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className={`tovar-input ${sizeSelect !== '' ? 'open-status' : ''}`}>
                            <h5 className={`size-status ${sizeSelect !== '' ? 'active' : ''}`}>
                                {stuff.sizes.filter((val) => val._id === sizeSelect)[0]?.count > 0 ?
                                    <span className="green">In stock</span>
                                    :
                                    <span className="red">Out of stock</span>
                                }
                            </h5>
                            <input 
                                type="text" 
                                className="input-text" 
                                value={cartInput} 
                                onChange={handleCountInput}
                                onBlur={(e) => e.target.value < 1 || e.target.value === '' ? setCardInput(1) : null}
                                inputMode="numeric"
                                autoComplete="off"
                            />
                            <button className={`${sizeSelect !== '' && !sizeOutOfStock ? 'active' : ''}`} onClick={handleAddToCart}>Add to Cart</button>
                        </div>
                        <div className={`waitlist ${sizeOutOfStock ? 'active' : ''}`}>
                            {witelistSuccess ? 
                            <div className="witelist-success">
                                <p>You are now in witelist. We will inform you as soon as we are back in stock.</p>
                            </div> 
                            :
                            <>
                                <h3>Join Waitlist</h3>
                                <p>We will inform you when the product arrives in stock. Please leave your valid email address below.</p>
                                <form onSubmit={addToWitelist}>
                                    <label>
                                        <span>@</span>
                                        <input 
                                            type="email" 
                                            name="email" 
                                            autoComplete="email" 
                                            placeholder="Email" 
                                            required
                                            value={witelistEmail}
                                            onChange={(e) => setWiteListEmail(e.target.value)}
                                        />
                                    </label>
                                    <button>Email me when available</button>
                                </form>
                            </>
                        }
                        </div>
                    </div>
                </div>
                <div className="subcontext">
                    <div className="tab-block">
                        <button className={`${isDescription ? 'active' : ''}`} onClick={(e) => setIsDescription(true)}>Description</button>
                        <button className={`${!isDescription ? 'active' : ''}`}  onClick={(e) => setIsDescription(false)}>Additional information</button>
                    </div>
                    {isDescription ? 
                        <div className={`description`}>
                            <h3>Description</h3>
                            <img src={descrOne} alt="Description one" />
                            <img src={descrTwo} alt="Description two" />
                        </div>
                        :
                        <div className={`additional-information ${!isDescription ? 'active' : ''}`}>
                            <h3>Additional information</h3>
                            <table>
                                <tr height="40">
                                    <td width="100"><h6>Weight</h6></td>
                                    <td><p>{stuff?.weight ? stuff.weight : "N/A" }</p></td>
                                </tr>
                                <tr height="40">
                                    <td width="100"><h6>Size</h6></td>
                                    <td><p>{stuff.sizes.map(val => val.size).join(', ')}</p></td>
                                </tr>
                            </table>
                        </div>
                    }
                </div>
                <p className="returns">
                    We accept returns within a week of receipt. <a href="../tos">Read more</a>.
                </p>
            </main>
            <Footer/>
        </> : null
    )
})

export default Tovar