export default async function addToWitelistApi(data) {
    try {
        const resp = await fetch(`${process.env.REACT_APP_SERVER_HOST}/api/v1/stuff/witelist`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        if (resp.status === 200) {
            return true
        }

        return false
    } catch (e) {
        return false
    }
}