import { observer } from 'mobx-react-lite';

import '../styles/MyAccount.scss'

import Footer from "../componens/Footer/Footer";
import Header from "../componens/Header/Header";
import MyAccountProfile from '../componens/MyAccountProfile/MyAccountProfile';
import MyAccountAuth from '../componens/MyAccount/MyAccountAuth';

import userStore from '../store/UserStore';

const MyAccount = observer(() => {
    return (
        <>
            <Header/>
            {userStore.isAuth ? <MyAccountProfile /> : <MyAccountAuth />}
            <Footer/>
        </>
    )
})

export default MyAccount;