export default async function deleteItemApi(stuffId, sizeId) {
    const path = `${process.env.REACT_APP_SERVER_HOST}/api/v1/cart`

    try {
        const resp = await fetch(path, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ stuffId, sizeId })
        })

        const json = await resp.json()

        if (resp.status === 200) {
            return json.result.cart
        } else {
            console.error(`Путь: ${path}\nКод: ${resp.status}\nСообщение: ${json.message}`)
        }
    } catch (e) {}
}