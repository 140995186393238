import { observer } from "mobx-react-lite";
import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import headerFon from '../../images/headerfon.png'
import cart from '../../images/cart.svg'

import './Header.scss'
import cartStore from '../../store/CartStore'
import userStore from "../../store/UserStore";
import pageStore from "../../store/PageStore";

const Header = observer(() => {
	const [ isOpenMenu, setIsOpenMenu ] = useState(false)

	return (
		cartStore.items ?
		<header>
			<a href="/" className="logotype" title="Home page">
				<img src={headerFon} alt="" title="sixthdimension.shop"/>
			</a>
			<div className={`burger ${isOpenMenu ? 'open' : ''}`} onClick={() => setIsOpenMenu(!isOpenMenu)}>
				<button></button>
			</div>
			<div className="line" id="lineheader"></div>
			<div className={`buttonsheader ${isOpenMenu ? 'open' : ''}`}>
				{pageStore.pages.length > 0 ? (
					pageStore.pages.map(val =>
						val.pages.length === 1 ? (
							val.pages.map((pg, index) => 
								<a href={`/${pg.path}`} key={index}>{pg.name}</a>	
							)
						) 
						: 
						<div className="dropdown" key={val._id}>
							<button className="dropbtn">{val.name}</button>
							<div className="dropdown-content">
								{val.pages.map((pg, index) =>
									<NavLink to={`/${pg.path}`} key={index}>{pg.name}</NavLink>	
								)}
							</div>
						</div>
					)
				) : null}
				
				<div className="cart__navbar">
					<Link to="../cart" onClick={() => !userStore.isAuth ? window.location.href = '../my-account' : null}>
						<img src={cart} alt=""/> {cartStore.items.length} ITEMS
					</Link>
				</div>
			</div>
		</header>
		: null
	)
})

export default Header