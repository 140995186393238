export default async function getOneTovarApi(id) {
    const path = `${process.env.REACT_APP_SERVER_HOST}/api/v1/stuff/${id}`

    try {
        const result = await fetch(path)
        const json = await result.json()

        if (result.status === 200) {
            return json.result
        } else {
            console.error(`Путь: ${path}\nСтатус: ${result.status}\nСообщение: ${json.message}`)
        }
    } catch (e) {
        console.error(`Путь: ${path}\nСообщение: Не удалось выполнить запрос на сервер`)
    }
}