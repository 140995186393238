export default async (formData) => {
    try {
        const resp = await fetch(`${process.env.REACT_APP_SERVER_HOST}/auth/login`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })

        const json = await resp.json()

        if (resp.status === 200) {
            return {result: true, token: json.result}
        } else {
            return {result: false, message: json.message}
        }
    } catch (e) {
        return {result: false, message: 'Error contacting server. Please try again in a few minutes.'}
    }
}