export default async function checkTokenApi(token) {
    try {
        const resp = await fetch(`${process.env.REACT_APP_SERVER_HOST}/auth/check`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })

        const json = await resp.json()

        if (resp.status === 200) {
            return {result: true, token: json.token}
        } else {
            return {result: false, message: json.message}
        }
    } catch (e) {
        return {result: false, message: 'Error contacting server. Please try again in a few minutes.'}
    }
}