import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import './MyAccountAuth.scss'

import loginApi from "../../api/auth/loginApi";

import useStore from "../../store/UserStore";
import singupApi from "../../api/auth/singupApi";
import codeApi from "../../api/auth/codeApi";
import userStore from "../../store/UserStore";

const MyAccountAuth = observer(() => {
    const [isConfirm, setIsConfirm] = useState(false)
    const [onError, setOnError] = useState('')
    const [login, setLogin] = useState({login: '', password: '', remember: false})
    const [singup, setSingup] = useState({username: '', email: '', password: ''})
    const [confirmCode, setConfirmCode] = useState('')

    const onSubmitLogin = async (e) => {
        e.preventDefault()

        const fetchApi = await loginApi(login)

        if (!fetchApi.result) {
            return setOnError(fetchApi.message)
        }

        localStorage.setItem('token', fetchApi.token)

        useStore.setAuth(true)
    }

    const onSubmitSingup = async (e) => {
        e.preventDefault()

        const fetchApi = await singupApi(singup)

        if (!fetchApi.result) {
            return setOnError(fetchApi.message)
        }

        setIsConfirm(true)
    }

    const handleConfirmCodeInput = (e) => {
        let value = e.target.value.replace(/\D/g, '');

        if (value.length > 6) {
            value = value.slice(0, 6);
        }

        if (value.length > 3) {
            value = value.slice(0, 3) + '-' + value.slice(3);
        }

        setConfirmCode(value);
    }

    const onSubmitConfirm = async (e) => {
        e.preventDefault()

        const fetchApi = await codeApi(confirmCode)

        if (!fetchApi.result) {
            return setOnError(fetchApi.message)
        }

        localStorage.setItem('token', fetchApi.token)
        userStore.setAuth(true)
    }

    return (
        <>
        {!isConfirm ? 
        <div className="authenticate">
            <h2>My Account</h2>
            <div className={`error-text ${!onError ? null : 'visible'}`}>
                <p>
                    <b>Error:</b> {onError}
                </p>
            </div>
            <div className="container">
                <form onSubmit={onSubmitLogin}>
                    <h3>Login</h3>
                    <label>
                        <p>Username or email address <b>*</b></p>
                        <input 
                            type="text" 
                            inputMode="text" 
                            value={login['login']}
                            required 
                            onChange={e => setLogin({...login, login: e.target.value})}
                        />
                    </label>
                    <label>
                        <p>Password <b>*</b></p>
                        <input 
                            type="password" 
                            inputMode="text" 
                            required 
                            value={login['password']}
                            onChange={e => setLogin({...login, password: e.target.value})}
                        />
                    </label>
                    <div className="button">
                        <button>LOG IN</button>
                        <label>
                            <input 
                                type="checkbox"
                                checked={login['remember']}
                                onChange={() => setLogin({...login, remember: !login.remember})}
                            />
                            <p>Remeber me</p>
                        </label>
                    </div>
                    <Link to={'/lost-password'}>Lost your password?</Link>
                </form>
                <form onSubmit={onSubmitSingup}>
                    <h3>Register</h3>
                    <label>
                        <p>Username <b>*</b></p>
                        <input 
                            type="text" 
                            inputMode="text" 
                            required
                            value={singup['username']}
                            onChange={e => setSingup({...singup, username: e.target.value})}
                        />
                    </label>
                    <label>
                        <p>Email address <b>*</b></p>
                        <input 
                            type="email" 
                            inputMode="email" 
                            required
                            value={singup['email']}
                            onChange={e => setSingup({...singup, email: e.target.value})}
                        />
                    </label>
                    <label>
                        <p>Password <b>*</b></p>
                        <input 
                            type="password" 
                            required
                            value={singup['password']}
                            onChange={e => setSingup({...singup, password: e.target.value})}
                        />
                    </label>
                    <p>
                        <input type="checkbox" required /> I agree with the <Link to="../hpi">personal data processing policy</Link>
                    </p>
                    <p>
                        Your personal data will be used to support your experience throughout this website, to manage access to your account, 
                        and for other purposes described in our <Link to='../tac'>privacy policy</Link>
                    </p>
                    <div className="button">
                        <button>Register</button>
                    </div>
                </form>
            </div>
        </div>
        :
        <div className="confirm">
            <h2>Confirm E-mail</h2>
            <div className={`error-text ${!onError ? null : 'visible'}`}>
                <p><b>Error: </b></p>
            </div>
            <form onSubmit={onSubmitConfirm}>
                <label>
                    <p>Enter the confirmation code from the email <b>*</b></p>
                    <input 
                        type="text" 
                        inputMode="numeric" 
                        required
                        onChange={handleConfirmCodeInput}
                        value={confirmCode}
                        autoComplete="one-time-code"
                        name="code"
                    />
                </label>
                <button>Confirm</button>
            </form>
        </div>
        }
    </>
    )
})

export default MyAccountAuth;