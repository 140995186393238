export default async function addToCartApi(tovar) {
    const resp = await fetch(`${process.env.REACT_APP_SERVER_HOST}/api/v1/cart`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(tovar)
    })

    const json = await resp.json()

    if (resp.status === 200) {
        return {result: true, tovar: json.result}
    }
}