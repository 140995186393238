export default async function cartApi() {
    try {
        const resp = await fetch(`${process.env.REACT_APP_SERVER_HOST}/api/v1/cart`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        })

        const json = await resp.json()

        if (resp.status === 200) {
            return json.result
        }
    } catch (e) {}
}