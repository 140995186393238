import './SCImage.scss'

const SCImage = ({content}) => {
    return ( 
        <div className="imgback">
            <img src={`${process.env.REACT_APP_SERVER_HOST}/images/${content}`} alt="perfect load" /> 
        </div>
    )
}

export default SCImage