import Footer from "../componens/Footer/Footer"
import Header from "../componens/Header/Header"
import cartStore from "../store/CartStore"

import { TiDelete } from "react-icons/ti";

import '../styles/Cart.scss'
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import deleteItemApi from "../api/cart/deleteItemApi";

const Cart = observer(() => {
    const [isDirty, setIsDirty] = useState(false)

    useEffect(() => {
        cartStore.getUserCart()
    }, [])

    const onDeleteTovar = async (stuffId, sizeId) => {
        let newItems = await deleteItemApi(stuffId, sizeId)
        cartStore.setItems(newItems)
    }

    const onUpdateCount = (e, index) => {
        const currentTovar = cartStore.items[index]
        const sizeId = currentTovar.sizeId
        const currentSize = currentTovar.stuffId.sizes.filter(s => s._id === sizeId)[0]

        if (e.target.value < 0) return e.target.value = 1

        if (currentSize.count >= e.target.value && e.target.value !== '0' && e.target.value !== '') {
            cartStore.setItems(
                cartStore.items.map((val, idx) => 
                    idx === index ? {...val, count: e.target.value} : val
                )
            )
        }

        setIsDirty(true)
    }

    const onCountBlur = (e, index) => {
        const count = cartStore.items[index].count
        if (e.target.value === '0' || e.target.value === '') e.target.value = count

        setIsDirty(true)
    }

    const applyChanges = async (e) => {
        await cartStore.updateCartItems()
        setIsDirty(false)
    }

    return (
        <>
            <Header/>
            <main className="cart-body">
                <h2>Cart</h2>
                {cartStore.items.length > 0 ?
                    <div className="tovar-table">
                        <table cellSpacing="0">
                            <tbody>
                                <tr className="title">
                                    <th></th>
                                    <th></th>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Subtotal</th>
                                </tr>
                                {cartStore.items.map((tovar, index) => {
                                    const size = tovar.stuffId.sizes.filter(s => s._id === tovar.sizeId)[0]
                                    const price = tovar.stuffId.price

                                    return <tr key={index}>
                                        <td>
                                            <p onClick={() => onDeleteTovar(tovar.stuffId, tovar.sizeId)}>
                                                <TiDelete/>
                                            </p>
                                        </td>
                                        <td>
                                            <img src={`${process.env.REACT_APP_SERVER_HOST}/images/${tovar.stuffId.images[0]}`} alt={tovar.stuffId.name} />
                                        </td>
                                        <td>
                                            <Link target="_blank" to={`../tovar/${tovar.stuffId._id}`}>{tovar.stuffId.name} - {size?.size}</Link>
                                        </td>
                                        <td>
                                            <p>{price}₽</p>
                                        </td>
                                        <td>
                                            <input 
                                                type="number" 
                                                inputMode="numeric"
                                                value={cartStore.items[index].count}
                                                onChange={e => onUpdateCount(e, index)}
                                                onBlur={e => onCountBlur(e, index)}
                                            />
                                        </td>
                                        <td width="200">
                                            <p>{tovar.stuffId.price * cartStore.items[index].count}₽</p>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <div className="subcart-block">
                            <div className="coupon">
                                <input type="text" placeholder="Coupon code"/>
                                <button className="sd-style active">apply coupon</button>
                            </div>
                            <button className={`sd-style ${isDirty ? 'active' : ''}`} onClick={applyChanges}>Save changes</button>
                        </div>
                        <div className="totals-container">
                            <h3>Cart totals</h3>
                            <table cellSpacing={0}>
                                <tbody>
                                    <tr>
                                        <th>Subtotal</th>
                                        <td>
                                            {cartStore.items.reduce((acc, obj) => {return +acc + (+obj.stuffId.price * +obj.count)}, 0)}₽
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Shipping</th>
                                        <td>
                                            Economic (10-15 business days): <b>500₽</b><br/>
                                            Shipping to <b>Russia</b>.<br/>
                                            <button>Change address</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Total</th>
                                        <td>{cartStore.items.reduce((acc, obj) => {return +acc + (+obj.stuffId.price * +obj.count)}, 0) + 500}₽</td>
                                    </tr>
                                </tbody>
                            </table>
                            <form className="gift-card">
                                <p>Have a gift card?</p>
                                <div className="input">
                                    <input type="text" placeholder="Gift card number"/>
                                    <button>apply</button>
                                </div>
                            </form>
                            <button className="pay sd-style active">Pay with Robokassa</button>
                        </div>
                    </div>
                    :
                    <div className="empty-cart">
                        <p>Your cart is currently empty.</p>
                    </div>
                }
            </main>
            <Footer/>
        </>
    )
})

export default Cart