import { useEffect, useState } from "react"
import CardOpacity from "../CardOpacity/CardOpacity"

import './SCTovar.scss'

const SCTovar = ({content}) => {
    const [tovars, setTovars] = useState([])

    useEffect(() => {
        const getCardsItem = async () => {
            let fetchedTovars = []

            for (let ct of content) {
                const resp = await fetch(`${process.env.REACT_APP_SERVER_HOST}/api/v1/stuff/${ct}`)

                if (resp.status === 200) {
                    const json = await resp.json()
                    fetchedTovars.push(json.result)
                }
            }

            setTovars(fetchedTovars)
        }

        getCardsItem()
    }, [])

    return (
        <div className="maintovarka">
            <div className="cardoneflex">
                {tovars.map((c, index) => 
                    <CardOpacity card={c} key={index} />
                )}
            </div>
        </div>
    )
}

export default SCTovar